import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";


const paginationLinks = ({ currentPage, numPages }) => {
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString();
	const nextPage = (currentPage + 1).toString();
	const prevUrl = `/blog/${prevPage}`.replace(/\/\//, "/");
	const nextUrl = `/blog/${nextPage}`;

	return (
		<>
			{!isFirst && (
				<Link to={prevUrl} rel="prev">
					← Previous Page
				</Link>
			)}
			{ !isFirst && !isLast && "｜" }
			{
				!isLast && (
					<Link to={nextUrl} rel="next">
						Next Page →
					</Link>
				)
			}
		</>
	);
};

export default class BlogList extends React.Component {
	render() {
		const pages = this.props.data.allMarkdownRemark.edges;
		const title = `Blog Entries Page ${this.props.pageContext.currentPage}`;
		return (
			<Layout>
				<SEO title={title} />
				<h1>{title}</h1>
				{pages.map(page => {
					const fm = page.node.frontmatter;
					return <article key={fm.url}>
						<header>
							<h2><Link to={fm.url}>{fm.title}</Link></h2>
							<p>{fm.date}</p>
						</header>
						<p>{page.node.excerpt}</p>
					</article>;
				})}
				
				<footer>
					{paginationLinks(this.props.pageContext)}
				</footer>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	query blogListQuery($skip: Int!, $limit: Int!) {
    	allMarkdownRemark(
			sort:{ order:DESC, fields:[frontmatter___date]}
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					id
					excerpt(pruneLength: 280)
					frontmatter {
						date(formatString:"DD MMMM YYYY")
						url
						title
					}
				}
			}
      	}
    }
`;

